import React from 'react';

const ModalDialogFaq = () => {
  return (
    <>
      <h3>Why are Santa&apos;s videos not playing on my device?</h3>
      <p className="incognito-info">
        Santa&apos;s videos will only work when your phone is NOT in Incognito/Private Mode. Please refer to your Internet browser&apos;s step-by-step instructions on how to disable Incognito/Private Mode.
      </p>
      <h3>What is a QR code?</h3>
      <p>
        A QR code is similar to a barcode. When you scan the Ornament’s QR code with the camera of your phone or tablet,
        it takes you to see video messages from Santa!
      </p>
      <h3>How do I scan a QR code?</h3>
      <p>
        Simply open the camera on your phone or tablet and point it at the QR code.Click on the link that appears and
        you will automatically be taken to see Santa’s videos.
      </p>
      <h3>What if my phone doesn’t have a QR code scanner?</h3>
      <p>You should be able to download a third-party QR code reader from most phone app stores.</p>
      <h3></h3>Do I need to download a third-party QR code scanning app onto my phone to use Santa’s Kindness Ornament?
      <p>
        No. Most phones’ cameras are capable of scanning QR codes with their built-in technology. If your phone does not
        have a QR code scanner, we recommend you only download a scanning app from the official IOS and Android app
        stores.
      </p>
      <p>
        NOTE: If you are using a third-party QR code app, you may experience paid advertisements that could interrupt
        your overall experience. Any paid advertisements you see are not part of DEMDACO’s Santa’s Kindness Ornament.
      </p>
      <p>
        CAUTION: Scammers have created fake “scanning apps” that install malware meant to steal your personal and
        account information when you download them onto your device. Warning Signs You Have Downloaded A Fake QR Code
        Scanning App:
        <ul>
          <li>The app asks for extensive permissions, like viewing and controlling your screen.</li>
          <li>The app is relatively new and cites reviews that sound fake or stolen.</li>
          <li>
            If the app asks for an update as soon as you download it, that’s a clear sign that it’s trying to install
            malware.
          </li>
        </ul>
      </p>
      <h3>Am I able to see the videos if I don’t have a way to scan the QR code?</h3>
      <p>No. You must scan the QR code with a phone or digital tablet to see Santa’s videos.</p>
      <h3>Am I able to see the videos if I don’t have an email address?</h3>
      <p>No. You must register using your email address and PIN Code to see the videos.</p>
      <h3>Why do I need to register?</h3>
      <p>
        Registration by an adult allows kids to have a safe online experience by delivering secure messages from Santa
        and provides protection against hacking, because only those who register can access the site and its content. It
        also allows us to communicate new updates and videos.
      </p>
      <p>
        We think it’s best that a parent accompanies a child on all their online adventures. While our experience is
        child safe, it’s best that parents participate in digital activities with their child(ren). Having a SAFE SCAN
        PIN ensures that a parent, guardian or trusted adult is present in the use of the Santa’s Kindness Ornament and
        enhances all participants online safety.
      </p>
      <h3>Why do you need my birthdate?</h3>
      <p>This information is used to verify that an adult is registering Santa’s Kindness Ornament.</p>
      <h3>Can children create an account?</h3>
      <p>
        No, children are not permitted to create an account or enter their personal information. An adult (18 years+)
        needs to set up the account in order to use the ornament.
      </p>
      <h3>Why are Santa’s videos not playing on my device?</h3>
      <p>
        Ensure that you have followed the registration process. Also turn on your browser’s ‘cookies’ so that you
        receive all messages in order.
      </p>
      <h3>What is a SAFE SCAN PIN and where is it located?</h3>
      <p>
        A SAFE SCAN PIN is a 7-digit alpha-numeric code located on the back of the ornament and is unique to your
        ornament. Keep this with the ornament for future use. You will use this PIN code to register your Ornament.
      </p>
      <p>
        If your PIN looks like it contains the letter “O” it could be the number zero (0). Or if it has the capital
        letter “I” it could actually be the number one (1), so try entering these options in its place instead. Only
        uppercase letters are used in SAFE SCAN PIN codes. If your pin doesn’t work for any other reason, contact our
        Customer Care department for assistance.
      </p>
      <h3>Can we only watch video messages from Santa on the device used to register the SAFE SCAN PIN?</h3>
      <p>
        No.You can register up to 3 devices per PIN Code. Only 1 email address is used to Register your Ornament, but a
        maximum total of 3 devices can be used to watch videos.Old devices can be removed once you have reached your
        device limit of three (3).
      </p>
      <h3>What if my SAFE SCAN PIN is missing/illegible on the back of my Ornament?</h3>
      <p>For assistance obtaining a new PIN Code, please email Hello@SantaKindness.com or call 888.336.3226.</p>
      <h3>Is Santa’s Kindness technology secure?</h3>
      <p>
        Yes. We have taken all reasonable steps to ensure your information is protected, secure and that no identifiable
        information is collected.
      </p>
      <h3>What do I do if I’m having additional technical issues?</h3>
      <p>
        For questions, information or to share your stories of kindness, please email Hello@SantaKindness.com or call
        888.336.3226.
      </p>
      <h3>What is included in the Santa’s Kindness Ornament box?</h3>
      <p>Santa’s Kindness Ornament with QR code and SAFE SCAN PIN, plus an interactive 80-page Kindness Journal.</p>
      <h3>Can more journals be purchased?</h3>
      <p>
        Yes, additional journals are available for purchase for families with more than one child. Visit demdaco.com to
        find a store near you or order on our website. Each Kindness Journal was made to capture the thoughts, ideas and
        memories of a single child – to serve as a keepsake for the family. Individual journals are sold separately at
        your local store or at santaskindness.com.
      </p>
      <h3>When does Santa’s Kindness Ornament start?</h3>
      <p>
        Starting the morning of Dec 1st, Santa will send a few messages each day asking for a child’s help sharing
        kindness.
      </p>
      <h3>How many video messages does Santa send each day?</h3>
      <p>
        From December 1st through 23rd, Santa will send 2-3 video messages per day. On December 24th, Santa will send
        several videos throughout the day as he prepares for his Christmas Eve flight. There will also be one video,
        each day, on December 25th and 26th.
      </p>
      <h3>What are some of the ways Santa will ask children to help spread kindness?</h3>
      <p>
        Just to name a few acts of kindness Santa will be asking kids for help with…giving hugs to family members,
        telling someone you love them, making and sharing meals/snacks, doing silly dances together, helping out around
        the home, giving hugs, drawing pictures, writing notes, giving everyone great big smiles and so much more!
      </p>
      <h3>Are the videos year-round?</h3>
      <p>No. Santa’s messages start on December 1st and run through the holiday season only.</p>
      <h3>Does the experience meet accessibility requirements?</h3>
      <p>Yes, plus all videos are captioned so that those with hearing challenges can still participate.</p>
      <h3>Can the videos be translated into another language?</h3>
      <p>No, not at this time.</p>
      <h3>Where can I find the Santa’s Kindness Ornament in Canada, Europe, UK, etc.?</h3>
      <p>At this time, the Ornament is only sold in the USA.</p>
      <h3>Can the videos be Closed Captioned?</h3>
      <p>
        Yes. Click the [CC] button in the lower-right corner of the screen. English is the only option for Closed
        Caption at this time.
      </p>
      <h3>Is Santa’s Kindness Ornament child safety tested?</h3>
      <p>
        Yes. The ornament is designed to be kid safe, and the digital experience is COPPA Compliant and a ho-ho-whole
        lot of fun.
      </p>
      <h3>What is the age range for this product?</h3>
      <p>Designed for kids ages 3+ and kids at heart of all ages.</p>
      <h3>Are batteries required?</h3>
      <p>
        No batteries are required. Just scan the QR code with your phone or tablet to start seeing messages from Santa!
      </p>
      <h3>What is the hole in the back of the ornament for?</h3>
      <p>
        You can add a holiday light string bulb to enhance the colors throughout the ornament while hanging on tree.
      </p>
      <h3>Is this ornament reusable the following year?</h3>
      <p>
        Yes, the QR code will be active each holiday season with new videos and kindness activities. Each year, we will
        deliver new ways to enhance the experience for families.
      </p>
      <h3>What will the Santa’s Kindness Ornament experience be like when I scan the ornament next Christmas?</h3>
      <p>
        While Santa is busy making final plans for what will happen when you scan the ornament this year, there are sure
        to be brand-new ways for children and families to help him share kindness.
      </p>
      <h3>Is this Kindness Journal reusable the following year?</h3>
      <p>We recommend new journals each season for increased memory keeping as children grow.</p>
      <h3>Is the Santa’s Kindness Ornament QR code active all year?</h3>
      <p>
        The QR/URL link will be active through the holiday season. Then, we’ll see you again next December for new
        messages from Santa.
      </p>
      <h3>Can Santa’s Kindness Ornament be purchased separately, without the journal?</h3>
      <p>No, the ornament may only be purchased together with the journal.</p>
      <h3>
        If my 3-year-old watches the first of three videos while my older kids are at school – will they get to see the
        first video later that day?
      </h3>
      <p>
        Yes, the videos rotate.After reaching the third video, the cycle will begin again at video 1 and rotate for the
        remainder of that day. Videos can be watched multiple times a day. This is true with the exception of Christmas
        Eve. On the 24th, several new videos will play throughout the day.
      </p>
      <h3>
        Videos from Santa begin on December 1st.If I didn’t buy my Ornament until December 10th, can we see the videos
        from the 1st through 10th?
      </h3>
      <p>No. Only the videos from the date of registration forward.</p>
      <h3>Why doesn’t my Coupon Code work on Santa’s Kindness products?</h3>
      <p>
        Unfortunately, the Santa’s Kindness line of products is excluded from all discount offers. But the total of
        these items will count toward your total for Free Shipping or giveaway thresholds.
      </p>
      <h3>How do I speak to a Customer Care representative?</h3>
      <p>
        During the month of November you can reach Santa’s Kindness Ornament Helpers, Monday through Friday from 8am -
        12pm & 12:30pm - 5pm Central Standard Time. In the month of December our Helpers will be available from 8AM- 9PM
        Central Standard Time, 7 days a week. You can contact us via phone at 888.336.3226 or email at
        hello@santakindness.com.
      </p>
    </>
  );
};

export default ModalDialogFaq;
