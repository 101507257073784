import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../index';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/login-form.scss';
import '../assets/scss/pages/pin-authorization.scss';
import { LOGIN_ROUTE, USER_ADD_PIN_ROUTE, USER_MESSAGE_MAXIMUM_NUMBER_DEVICES_ROUTE } from '../utils/consts';
import FormButtonSubmit from '../components/user/FormButtonSubmit';
import LayoutForm from '../components/user/LayoutForm';
import AuthUserService from '../services/AuthUserService';
import ModalDialogBoxContent from '../components/user/ModalDialogBoxContent';
import ModalDialogFaq from '../components/user/ModalDialogFaq';
import ModalDialogSupport from '../components/user/ModalDialogSupport';

const UserAuthorizationPin = () => {
  const { userStore, adminStore } = useContext(Context);
  const history = useNavigate();
  const formRef = useRef(null);
  const user = userStore.user;
  const pinRows = user.pins?.rows || [];
  const userPinId = user.currentPin?.id;

  // Initialize pinId from sessionStorage
  const [pinId, setPinId] = useState(() => {
    const storedPinId = sessionStorage.getItem('selectedPinId');
    return storedPinId ? Number(storedPinId) : undefined;
  });

  const [deactivatedPins, setDeactivatedPins] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [numberDevices, setNumberDevices] = useState(adminStore.numberDevices);

  useEffect(() => {
    if (pinRows.length === 0) history(USER_ADD_PIN_ROUTE);
  }, [pinRows]);

  // Ensure consistent sorting by id
  const sortPins = (pins) => [...pins].sort((a, b) => a.id - b.id);

  const numberEnabledPins = (pinRows) => {
    return pinRows.filter((pin) => !pin.blocked);
  };

  const disabledPins = (pinRows) => {
    return pinRows.filter((pin) => pin.blocked);
  };

  const firstActivePin = (pinRows) => {
    const arr = numberEnabledPins(pinRows);
    return arr.length > 0 && arr[0];
  };

  const currentActivePin = (pinRows, pinId) => {
    const arr = numberEnabledPins(pinRows);
    return arr.find((pin) => {
      return pin.id === Number(pinId);
    });
  };

  /*  const styleErrorDeviceLimit = (itemCountDevices, numberDevices, userPinId, pinId) => {
    if (
      (userPinId !== pinId && itemCountDevices >= numberDevices) ||
      (userPinId === pinId && itemCountDevices > numberDevices)
    ) {
      return 'text-red-700';
    } else if (userPinId === pinId && itemCountDevices === numberDevices) {
      return 'text-green-700';
    }
    return '';
  }; */

  useEffect(() => {
    setNumberDevices(adminStore.numberDevices);
    setDeactivatedPins(disabledPins(pinRows));

    if (numberEnabledPins(pinRows).length) {
      // If pinId is undefined or not in enabled pins, set it to userPinId or firstActivePin
      if (!pinId || !numberEnabledPins(pinRows).some((pin) => pin.id === Number(pinId))) {
        const id = pinId || userPinId || firstActivePin(pinRows)?.id;
        setPinId(id);
      }
    }

    const form = formRef.current;
    const formData = form.querySelectorAll('[type="radio"]:checked');

    if (!numberEnabledPins(pinRows).length || formData.length === 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [adminStore.numberDevices, pinRows, pinId]);

  // Store pinId in sessionStorage when it changes
  useEffect(() => {
    if (pinId !== undefined) {
      sessionStorage.setItem('selectedPinId', pinId);
    }
  }, [pinId]);

  const pinAuthorization = async () => {
    userStore.setPinIdAuthorization(Number(pinId));
    const currentPin = currentActivePin(pinRows, pinId);
    // console.info("count:",currentPin.countDevices);
    if (
      (userPinId !== pinId && currentPin.countDevices >= numberDevices) ||
      (userPinId === pinId && currentPin.countDevices > numberDevices)
    ) {
      history(USER_MESSAGE_MAXIMUM_NUMBER_DEVICES_ROUTE);
    } else {
      try {
        await AuthUserService.bindDeviceToPin(pinId, navigator.userAgent).then((response) => {
          if (response.data.device.deviceToken) {
            const token = response.data.device.deviceToken;
            // console.info("token:",token);
            window.location.href = adminStore.playerUrl + '?token=' + token;
          } else {
            history(LOGIN_ROUTE);
          }
        });
      } catch (e) {
        console.error(e.response.data.message);
        window.location.reload();
      }
    }
  };

  const handleClick = async () => {
    await userStore.logout();
    sessionStorage.removeItem('selectedPinId'); // Clear selectedPinId from sessionStorage on logout
  };

  return (
    <LayoutForm classSelector={'login-form pin-authorization'} title={'Ornaments'}>
      <span>It looks like you have an ornament(s). Please select an ornament to associate this device with.</span>
      {/* <div className={"form-info sc-r"}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#006548"
          />
        </svg>
        Each ornament is limited to {numberDevices} {numberDevices === 1 ? "device" : "devices"}.
      </div> */}
      <form action="" method="" ref={formRef}>
        <div className={'label-radio-group sc-r'}>
          {numberEnabledPins(pinRows).length > 0 ? 'Select an ornament:' : 'No active ornament(s) available.'}
        </div>
        <div className="input-radio" role={'radiogroup'} aria-label="Type" aria-hidden="false">
          {numberEnabledPins(pinRows).length > 0 &&
            sortPins(numberEnabledPins(pinRows)).map((item) => (
              <label key={item.id} htmlFor={`ornament_${item.id}`}>
                <input
                  onChange={(e) => {
                    const selectedId = Number(e.target.value);
                    setPinId(selectedId);
                    sessionStorage.setItem('selectedPinId', selectedId);
                  }}
                  value={item.id}
                  name="pin"
                  type="radio"
                  checked={Number(pinId) === item.id}
                  id={`ornament_${item.id}`}
                />
                <span className="icon-radio"></span>
                <div className="input-radio-text mt-2">
                  <span className="pin-name">{item.name ? `${item.name} (${item.pin})` : item.pin}</span>
                  {/* <span
                    className={`device-count ${styleErrorDeviceLimit(
                      item.countDevices,
                      numberDevices,
                      userPinId,
                      item.id,
                    )}`}
                  >
                    {item.countDevices} {item.countDevices === 1 ? 'device' : 'devices'} connected
                  </span> */}
                </div>
              </label>
            ))}
        </div>
        <div className="block-add-component">
          <Link to={USER_ADD_PIN_ROUTE}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="#006548" />
            </svg>
            Add ornament
          </Link>
        </div>
        {deactivatedPins.length > 0 && (
          <div className="block-deactivated-ornament">
            <div className={'label-deactivated-ornament sc-r'}>Deactivated ornament(s):</div>
            {deactivatedPins.map((item) => (
              <div key={item.id} className="pin-name sc-r">
                {item.name ? `${item.name} (${item.pin})` : item.pin}
              </div>
            ))}
          </div>
        )}
        <FormButtonSubmit
          name={'Next'}
          color={disableButton ? 'default' : 'red'}
          callback={pinAuthorization}
          disabled={disableButton}
        />
        <div className="text-center my-3">
          <p className="h5">Having Issues?</p>
        </div>

        <div className="text-center my-4">
          <p className="incognito-info">
            Santa&apos;s videos will only work when your phone is NOT in Incognito/Private Mode. Please refer to your Internet browser&apos;s step-by-step instructions on how to disable Incognito/Private Mode.
          </p>
        </div>

        <div className="text-center my-3">
          <p className="h5">If You&apos;re Still Having Issues, Please Sign Out Then Sign Back In.</p>
        </div>

        <div className="text-center">
          <Link className="h5" onClick={handleClick}>
            Sign Out
          </Link>
        </div>

        <div className="block-info">
          <strong>Can’t find your PIN?</strong>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalFAQ'}>
            View FAQ
          </a>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalSupport'}>
            Contact Support
          </a>
        </div>
      </form>

      <ModalDialogBoxContent title={'Frequently Asked Questions'} modalId={'modalFAQ'} classSelector={'full-height'}>
        <ModalDialogFaq />
      </ModalDialogBoxContent>

      <ModalDialogBoxContent title={'Support'} modalId={'modalSupport'}>
        <ModalDialogSupport />
      </ModalDialogBoxContent>
    </LayoutForm>
  );
};

export default observer(UserAuthorizationPin);
